/**
 * Contact card switch
 *
 * @param  object   $el
 * @return function
 */
import toggleClasses from './utils/ToggleClasses.js';

export default function($el) {
  for (var i = 0; i < $el.length; i++) {
    const $switch = $el[i].querySelector('[data-contact-switch]');
    const $panel = $el[i].querySelectorAll('[data-contact-panel]');

    if ($switch) {
      $switch.addEventListener('click', function(e) {
        for (var i = 0; i < $panel.length; i++) {
          toggleClasses($panel[i], 'u-hidden');
        }
      });
    }
  }
}
