// Plugins
require('./vendor/intersection-observer');
const lozad = require('./vendor/lozad.js');

// Polyfill for statistics
// @see https://leaverou.github.io/conic-gradient/
require('./vendor/prefixfree.js');
require('./vendor/conic-gradient.js');

// Modules
import {constConsent} from './mjs/Constants.js';
import contact  from './mjs/Contact.js';
import isChecked from './mjs/utils/IsChecked.js';
import addClasses from './mjs/utils/ClassAdd.js';
import removeClasses from './mjs/utils/ClassRemove.js';
import {consent}  from './mjs/Consent.js';

// DOM ready
(function() {
  const observer = lozad(); // lazy loads elements with default selector as '.lozad'
  observer.observe();


  /**
   * Init animated scrolling
   */
  const $contact = document.querySelectorAll('[data-contact]');
  if ($contact) {
    contact($contact);
  }


  /**
   * Cookie consent
   */
  const $consent = document.querySelector(constConsent.wrapper);
  if ($consent) {
    consent($consent);
  }


   /**
    * Disable scrolling while mobile
    * menu is open
    */
   const $menu = document.querySelector('.js-menu-checkbox');
   if ($menu) {
     $menu.addEventListener('change', function(e) {
       if (isChecked(this)) {
         console.log('jo');
         addClasses(document.body, 'overflow-hidden');
       } else {
         removeClasses(document.body, 'overflow-hidden');
       }
     });
   }

   /**
    * Stop animations and transitions
    * on window resize
    *
    * @type {Function}
    */
   let resizeTimer;
   window.addEventListener("resize", () => {
     document.body.classList.add("resize-animation-stopper");
     clearTimeout(resizeTimer);
     resizeTimer = setTimeout(() => {
       document.body.classList.remove("resize-animation-stopper");
     }, 400);
   });
})();
